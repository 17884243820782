<template>
	<div>
		<b-row>
			<b-col>
				<b-button
					v-if="$can('addresses', 'create')"
					class="float-right"
					variant="primary"
					@click="$router.push({ name: addView, params: {'userId': userId} });">
					{{ translate('create') }}
				</b-button>
			</b-col>
		</b-row>
		<hr>
		<b-row>
			<b-col>
				<div class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0">
									#
								</th>
								<th
									class="border-top-0">
									{{ translate('first_name') }}
								</th>
								<th
									class="border-top-0">
									{{ translate('last_name') }}
								</th>
								<th
									class="border-top-0">
									{{ translate('address') }}
								</th>
								<th
									class="border-top-0">
									{{ translate('address2') }}
								</th>
								<th
									class="border-top-0">
									{{ translate('country') }}
								</th>
								<th
									class="border-top-0">
									{{ translate('state') }}
								</th>
								<th
									class="border-top-0">
									{{ translate('city') }}
								</th>
								<th
									class="border-top-0">
									{{ translate('postcode') }}
								</th>
								<th class="border-top-0">
									{{ translate('options') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!addressBookLoading && addressBookData">
							<tr
								v-for="(item, index) in addressBookData"
								:key="index">
								<td class="align-middle">
									{{ index + 1 }}
								</td>
								<td class="align-middle">
									{{ item.attributes.first_name }}
								</td>
								<td class="align-middle">
									{{ item.attributes.last_name }}
								</td>
								<td class="align-middle">
									{{ item.attributes.address }}
								</td>
								<td class="align-middle">
									{{ item.attributes.address2 }}
								</td>
								<td class="align-middle">
									<img
										v-b-tooltip.hover
										class="ml-3"
										:title="getCountry(item.attributes.country.code)"
										:src="item.attributes.country ? require(`@/assets/images/common/countries/select/${item.attributes.country.code.toUpperCase()}.png`) : ''"
										:alt="getCountry(item.attributes.country.code)">
								</td>
								<td class="align-middle">
									{{ item.attributes.region.name }}
								</td>
								<td class="align-middle">
									{{ item.attributes.city }}
								</td>
								<td class="align-middle">
									{{ item.attributes.postcode }}
								</td>
								<td class="align-middle text-center">
									<template
										v-if="showButtons()">
										<b-button
											v-if="$can('addresses', 'delete')"
											v-b-tooltip.hover
											:title="translate('delete_thing', {name: translate('address')})"
											variant="primary"
											class="bg-primary-alt"
											@click="deleteAddress(item)">
											<i
												class="fa fa-trash"
												aria-hidden="true" />
										</b-button>
									</template>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="addressBookLoading"
			:has-data="addressBookHasData" />
	</div>
</template>
<script>
import AddressBook from '@/util/AddressBook';
import {
	Addresses, Countries, Tooltip, Alerts, Profile,
} from '@/translations';

export default {
	name: 'AddressesTable',
	messages: [Addresses, Countries, Profile, Tooltip, Alerts],
	props: {
		userId: {
			type: String,
			default: '',
		},
		addView: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			addressBook: new AddressBook(),
			alert: new this.$Alert(),
		};
	},
	computed: {
		addressBookLoading() {
			try {
				return this.addressBook.data.loading;
			} catch (error) {
				return false;
			}
		},
		addressBookData() {
			try {
				const { data } = this.addressBook.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		addressBookHasData() {
			const response = this.addressBookData ? Object.keys(this.addressBookData).length : false;
			return !!response;
		},
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.addressBook.getAddresses(this.userId);
		},
		showButtons() {
			return this.$can('addresses', 'delete');
		},
		getCountry(item) {
			try {
				return this.translate(item.toLowerCase());
			} catch (error) {
				return '';
			}
		},
		deleteAddress(address) {
			const trans = {
				title: this.translate('delete_address_title'),
				text: this.translate('delete_address_text', { address: address.attributes.address }),
			};
			const config = {
				confirmButtonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
			};

			this.alert.confirmation(trans.title, trans.text, config, true).then(() => {
				this.addressBook.deleteAddress(this.userId, address.id).then(() => {
					this.alert.toast('success', this.translate('address_deleted'));
					this.getData();
				}).catch(() => {
					this.alert.toast('error', this.translate('something_went_wrong'));
				});
			}).catch(() => {});
		},
	},
};
</script>
