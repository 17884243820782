import { GET_USER_FILES, UPLOAD_USER_FILE, DELETE_USER_FILE } from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

const axios = new Req();

class UserFile {
	constructor() {
		this.data = new Req(apiFilters);
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
		this.options = {};
	}

	getFiles(userId) {
		const { endpoint, method } = GET_USER_FILES;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	deleteFile(fileId, payload) {
		const { endpoint, method } = DELETE_USER_FILE;
		return this.data[method](endpoint(fileId), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	uploadFile(userId, options) {
		const { endpoint, method } = UPLOAD_USER_FILE;
		this.options.data = options;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint(userId);
		this.options.method = method;

		return axios.postFormData(this.options).then((response) => {
			this.data = response;
			this.pagination = axios.pagination;
			return this.data;
		}).catch((errors) => Promise.reject(errors)).finally(() => {
			this.loading = false;
			return null;
		});
	}
}

export default UserFile;
