<template>
	<div>
		<b-row
			v-if="!profileLoading && profileHasData">
			<b-col>
				<b-card-body style="padding: unset;">
					<span
						class="btn btn-link float-right"
						@click="collapseAll">
						<i
							:class="allExpanded ? 'fa-angle-up' : 'fa-angle-down'"
							class="fas rotate-icon mr-1" />
						{{ allExpanded ? translate('collapse_all') : translate('expand_all') }}</span>
				</b-card-body>
			</b-col>
		</b-row>
		<b-row
			class="mt-2">
			<b-col>
				<b-card
					no-body
					class="mb-1">
					<b-card-header
						header-tag="header"
						class="p-1"
						role="tab">
						<b-button
							v-b-toggle.personal_info
							block
							class="text-left font-weight-bold"
							href="#"
							variant="default">
							<h5 class="mb-0 d-inline">
								{{ translate('profile_information') }}
							</h5>
							<span class="when-opened"><i class="fas fa-angle-up rotate-icon" /></span>
							<span class="when-closed"><i class="fas fa-angle-down rotate-icon" /></span>
						</b-button>
					</b-card-header>
					<b-collapse
						id="personal_info"
						v-model="personalExpanded"
						accordion="personal-info"
						role="tabpanel">
						<b-card-body>
							<template
								v-if="!profileLoading && profileHasData">
								<b-button
									v-if="$can('personal_information', 'suspend') && !['suspended'].includes(showableProfileData['status']) && !noEditableStatuses.includes(profileData['status'])"
									class="float-right mb-2"
									variant="danger"
									@click="suspendAccount">
									{{ translate('suspend') }}
								</b-button>
								<div class="table-responsive">
									<table class="table">
										<tbody>
											<editable-field
												v-for="item in Object.keys(showableProfileData)"
												v-if="item !== 'username'"
												:key="item"
												:key-value="item"
												:field-information="checkFieldInformation(item, showableProfileData, 'personal_information')"
												:field-name="translate(item)"
												:first-item="translate(Object.keys(showableProfileData)[0])"
												:errors="personalInfoErrors[item]"
												:loading="profileInfoLoading"
												:duplicated-info="duplicatedFields(item)"
												:options="getOptions(item)"
												:datepicker-popover="dateComponent.includes(item) ? { positionFixed: true } : {}"
												@clearField="clearPersonalError(item)"
												@editField="editProfile($event, item)" />
										</tbody>
									</table>
								</div>
							</template>
							<is-loading
								:loading-label="translate('loading')"
								:no-data-label="translate('data_not_found')"
								:loading="profileLoading"
								:has-data="profileHasData" />
						</b-card-body>
					</b-collapse>
				</b-card>
			</b-col>
		</b-row>
		<b-row
			class="mt-2">
			<b-col>
				<b-card
					no-body
					class="mb-1">
					<b-card-header
						header-tag="header"
						class="p-1"
						role="tab">
						<b-button
							v-b-toggle.account_info
							block
							class="text-left font-weight-bold"
							href="#"
							variant="default">
							<h5 class="mb-0 d-inline">
								{{ translate('account_information') }}
							</h5>
							<span class="when-opened"><i class="fas fa-angle-up rotate-icon" /></span>
							<span class="when-closed"><i class="fas fa-angle-down rotate-icon" /></span>
						</b-button>
					</b-card-header>
					<b-collapse
						id="account_info"
						v-model="accountExpanded"
						accordion="account-info"
						role="tabpanel">
						<b-card-body>
							<template
								v-if="!accountInformationLoading && accountInformationHasData">
								<b-button
									v-if="$can('account_information', 'update') && accountInformationData.enable_autoship"
									class="float-right mb-2"
									variant="danger"
									@click="editAccountInfo(false, 'enable_autoship')">
									{{ translate('suspend_autoship') }}
								</b-button>
								<div class="table-responsive">
									<table class="table">
										<tbody>
											<editable-field
												v-for="item in Object.keys(showableAccountInformationData)"
												:key="item"
												:key-value="item"
												:field-information="checkFieldInformation(item, showableAccountInformationData, 'account_information')"
												:field-name="translate(item)"
												:first-item="translate(Object.keys(showableAccountInformationData)[0])"
												:errors="accountErrors[item]"
												:loading="accountInformationLoading"
												:options="getOptions(item)"
												:datepicker-popover="dateComponent.includes(item) ? { positionFixed: true } : {}"
												@clearField="clearAccountError(item)"
												@editField="editAccountInfo($event, item)" />
										</tbody>
									</table>
								</div>
							</template>
							<is-loading
								:loading-label="translate('loading')"
								:no-data-label="translate('data_not_found')"
								:loading="accountInformationLoading"
								:has-data="accountInformationHasData" />
						</b-card-body>
					</b-collapse>
				</b-card>
			</b-col>
		</b-row>
		<b-row
			class="mt-2">
			<b-col>
				<b-card
					no-body
					class="mb-1">
					<b-card-header
						header-tag="header"
						class="p-1"
						role="tab">
						<b-button
							v-b-toggle.addresses_information
							block
							class="text-left font-weight-bold"
							href="#"
							variant="default">
							<h5 class="mb-0 d-inline">
								{{ translate('addresses_information') }}
							</h5>
							<span class="when-opened"><i class="fas fa-angle-up rotate-icon" /></span>
							<span class="when-closed"><i class="fas fa-angle-down rotate-icon" /></span>
						</b-button>
					</b-card-header>
					<b-collapse
						id="addresses_information"
						v-model="addressesExpanded"
						accordion="addresses_information-info"
						role="tabpanel">
						<b-card-body>
							<addresses-table
								:user-id="$route.params.customerId"
								add-view="AddCustomerAddress" />
						</b-card-body>
					</b-collapse>
				</b-card>
			</b-col>
		</b-row>
		<!-- <b-row
			class="mt-2">
			<b-col>
				<b-card
					no-body
					class="mb-1">
					<b-card-header
						header-tag="header"
						class="p-1"
						role="tab">
						<b-button
							v-b-toggle.system_credentials
							block
							class="text-left font-weight-bold"
							href="#"
							variant="default">
							<h5 class="mb-0 d-inline">
								{{ translate('system_credentials') }}
							</h5>
							<span class="when-opened"><i class="fas fa-angle-up rotate-icon" /></span>
							<span class="when-closed"><i class="fas fa-angle-down rotate-icon" /></span>
						</b-button>
					</b-card-header>
					<b-collapse
						id="system_credentials"
						v-model="credentialsExpanded"
						accordion="system-credentials"
						role="tabpanel">
						<b-card-body>
							<template
								v-if="!systemCredentialsLoading && systemCredentialsHasData">
								<div class="table-responsive">
									<table class="table">
										<tbody>
											<editable-field
												v-for="item in Object.keys(systemCredentialsData)"
												v-if="showField(item, systemCredentialsData)"
												:key="item"
												:key-value="item"
												:field-information="checkFieldInformation(item, systemCredentialsData, 'system_credentials')"
												:field-name="translate(item)"
												:first-item="translate(Object.keys(systemCredentialsData)[0])"
												:errors="systemCredentialsErrors[item]"
												:loading="systemCredentialsLoading"
												:options="getOptions(item)"
												@clearField="clearSystemCredentialsError(item)"
												@editField="editSystemCredentials($event, item)" />
											<editable-field
												:key="'new_password'"
												:key-value="'new_password'"
												:field-information="{ showable: '********', value: '', editable: $can('system_credentials', 'update') }"
												:field-name="translate('password')"
												:errors="systemCredentialsErrors['new_password']"
												:loading="systemCredentialsLoading"
												@clearField="clearSystemCredentialsError('new_password')"
												@editField="editSystemCredentials($event, 'new_password')" />
											<editable-field
												:key="'tfa_enabled'"
												:key-value="'tfa_enabled'"
												:field-information="{ showable: !!systemCredentialsData['tfa_enabled'], value: !!systemCredentialsData['tfa_enabled'], editable: $can('system_credentials', 'update') && !!systemCredentialsData['tfa_enabled'] }"
												:field-name="translate('two_factor_auth')"
												:errors="systemCredentialsErrors['tfa_enabled']"
												:loading="systemCredentialsLoading"
												@clearField="clearSystemCredentialsError('tfa_enabled')"
												@editField="editSystemCredentials($event, 'tfa_enabled')" />
										</tbody>
									</table>
								</div>
							</template>
							<is-loading
								:loading-label="translate('loading')"
								:no-data-label="translate('data_not_found')"
								:loading="systemCredentialsLoading"
								:has-data="systemCredentialsHasData" />
						</b-card-body>
					</b-collapse>
				</b-card>
			</b-col>
		</b-row> -->
		<b-row
			v-if="$can('file', 'view')"
			class="mt-2">
			<b-col>
				<b-card
					no-body
					class="mb-1">
					<b-card-header
						header-tag="header"
						class="p-1"
						role="tab">
						<b-button
							v-b-toggle.personal_files
							block
							class="text-left font-weight-bold"
							href="#"
							variant="default">
							<h5 class="mb-0 d-inline">
								{{ translate('personal_files') }}
							</h5>
							<span class="when-opened"><i class="fas fa-angle-up rotate-icon" /></span>
							<span class="when-closed"><i class="fas fa-angle-down rotate-icon" /></span>
						</b-button>
					</b-card-header>
					<b-collapse
						id="personal_files"
						v-model="personalFilesExpanded"
						accordion="personal_files"
						role="tabpanel">
						<b-card-body>
							<files-table
								:user-id="$route.params.customerId"
								add-view="UploadCustomersFile" />
						</b-card-body>
					</b-collapse>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import EditableField from '@/components/EditableField';
import CustomersInformation from '@/util/CustomersInformation';
import ProfileInfo from '@/util/Profile';
import {
	Profile, CustomersInformation as messages, Countries, Users,
} from '@/translations';
import { MDY_FORMAT, YMD_FORMAT } from '@/settings/Dates';
import {
	CUSTOMER_PERSONAL_FIELDS, EDITABLE_PERSONAL_FIELDS,
	CUSTOMER_ACCOUNT_FIELDS, EDITABLE_ACCOUNT_FIELDS,
	// CUSTOMER_SYSTEM_CREDENTIALS_FIELDS, EDITABLE_SYSTEM_CREDENTIALS_FIELDS,
	DATE_KEYS as dateKeys, BOOLEAN_KEYS as booleanKeys, TRANSLATED_KEYS as translatedKeys,
	INVISIBLE_FIELDS, DATE_COMPONENT as dateComponent, PASSWORD_COMPONENT as passwordComponent,
} from '@/settings/Profile';
import { CUSTOMER_EDITABLE_STATUSES as editableStatuses, NO_EDITABLE_STATUSES as noEditableStatuses } from '@/settings/Statuses';
import { MARKETING_PROVIDER as marketingProvider } from '@/settings/General';
import WindowSizes from '@/mixins/WindowSizes';
import AddressesTable from '@/views/Addresses';
import FilesTable from '@/views/PersonalFiles';

export default {
	name: 'CustomersInformation',
	messages: [messages, Profile, Countries, Users],
	components: { AddressesTable, FilesTable, EditableField },
	mixins: [WindowSizes],
	data() {
		return {
			profile: new CustomersInformation(),
			profileInfo: new ProfileInfo(),
			accountInfo: new CustomersInformation(),
			accountInformation: new CustomersInformation(),
			// systemCredentials: new ProfileInfo(),
			dateFormat: MDY_FORMAT,
			YMD_FORMAT,
			alert: new this.$Alert(),
			booleanKeys,
			dateKeys,
			editableFields: [
				...EDITABLE_PERSONAL_FIELDS.filter((field) => CUSTOMER_PERSONAL_FIELDS.includes(field)),
				...EDITABLE_ACCOUNT_FIELDS.filter((field) => CUSTOMER_ACCOUNT_FIELDS.includes(field)),
				// ...EDITABLE_SYSTEM_CREDENTIALS_FIELDS.filter((field) => CUSTOMER_SYSTEM_CREDENTIAL_FIELDS.includes(field))
			],
			translatedKeys,
			marketingProvider,
			allExpanded: true,
			editableStatuses,
			noEditableStatuses,
			passwordComponent,
			personalExpanded: true,
			accountExpanded: false,
			addressesExpanded: false,
			personalFilesExpanded: false,
			// credentialsExpanded: false,
			dateComponent,
		};
	},
	computed: {
		profileLoading() {
			try {
				return this.profile.data.loading;
			} catch (error) {
				return false;
			}
		},
		profileInfoLoading() {
			try {
				return this.profileInfo.data.loading;
			} catch (error) {
				return false;
			}
		},
		profileData() {
			try {
				const data = this.profile.data.response.data.data.attributes;
				return CUSTOMER_PERSONAL_FIELDS.reduce((response, key) => {
					if (typeof data[key] === 'undefined') return response;
					return { ...response, [key]: data[key] };
				}, {});
			} catch (error) {
				return {};
			}
		},
		showableProfileData() {
			try {
				return this.filterShowableFields(this.profileData);
			} catch (error) {
				return {};
			}
		},
		username() {
			try {
				return this.profileData.username;
			} catch (error) {
				return {};
			}
		},
		profileHasData() {
			const response = Object.keys(this.profileData).length;
			return !!response;
		},
		accountInformationLoading() {
			try {
				return this.accountInfo.data.loading;
			} catch (error) {
				return false;
			}
		},
		accountInformationData() {
			try {
				const data = this.accountInfo.data.response.data.response;
				return CUSTOMER_ACCOUNT_FIELDS.reduce((response, key) => {
					if (typeof data[key] === 'undefined') return response;
					return { ...response, [key]: data[key] };
				}, {});
			} catch (error) {
				return {};
			}
		},
		showableAccountInformationData() {
			try {
				return this.filterShowableFields(this.accountInformationData);
			} catch (error) {
				return {};
			}
		},
		accountInformationHasData() {
			const response = Object.keys(this.accountInformationData).length;
			return !!response;
		},
		// systemCredentialsLoading() {
		// 	try {
		// 		return this.systemCredentials.data.loading;
		// 	} catch (error) {
		// 		return false;
		// 	}
		// },
		// systemCredentialsData() {
		// 	try {
		// 		const data = this.systemCredentials.data.response.data.data.attributes;
		// 		return CUSTOMER_SYSTEM_CREDENTIALS_FIELDS.reduce((response, key) => {
		// 			if (typeof data[key] === 'undefined') return response;
		// 			return { ...response, [key]: data[key] };
		// 		}, {});
		// 	} catch (error) {
		// 		return {};
		// 	}
		// },
		// systemCredentialsHasData() {
		// 	const response = Object.keys(this.systemCredentialsData).length;
		// 	return !!response;
		// },
		personalInfoErrors: {
			get() {
				try {
					return this.profileInfo.data.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.profileInfo.data.errors.errors = newErrors;
			},
		},
		accountErrors: {
			get() {
				try {
					return this.accountInformation.data.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.accountInformation.data.errors.errors = newErrors;
			},
		},
		// systemCredentialsErrors: {
		// 	get() {
		// 		try {
		// 			return this.systemCredentials.data.errors.errors;
		// 		} catch (error) {
		// 			return {};
		// 		}
		// 	},
		// 	set(newErrors) {
		// 		this.systemCredentials.data.errors.errors = newErrors;
		// 	},
		// },
	},
	watch: {
		username(user) {
			this.$parent.titleParam = { user };
		},
	},
	mounted() {
		this.accountInfo.getInformation(this.$route.params.customerId);
		this.profile.getProfile(this.$route.params.customerId);
		// this.systemCredentials.getSystemCredentials(this.$route.params.customerId);
	},
	methods: {
		suspendAccount() {
			const trans = {
				title: this.translate('suspend_account'),
				passwordTitle: this.translate('password'),
				notesTitle: this.translate('notes'),
			};

			const attributes = {
				emptyPasswordMessage: this.translate('password_required'),
			};

			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.passwordTitle, trans.notesTitle, attributes).then((response) => {
				const { value } = response;
				const payload = {
					password: value.password,
					notes: value.notes,
				};
				this.profileInfo.suspendAccount(this.$route.params.customerId, payload).then(() => {
					this.alert.toast('success', this.translate('user_updated'));
					this.profile.getProfile(this.$route.params.customerId);
				}).catch(() => {
					let errorName = 'default_error_message';
					if (this.hasPersonalInfoError('password')) {
						errorName = 'incorrect_password';
					}
					this.alert.toast('error', this.translate(errorName));
					this.profile.getProfile(this.$route.params.customerId);
				});
			}).catch(() => {});
		},
		hasPersonalInfoError(field) {
			if (typeof this.personalInfoErrors[field] !== 'undefined') { return true; }
			return false;
		},
		hasAccountInfoError(field) {
			if (typeof this.accountErrors[field] !== 'undefined') { return true; }
			return false;
		},
		// hasSystemCredentialsErrors(field) {
		// 	if (typeof this.systemCredentialsErrors[field] !== 'undefined') { return true; }
		// 	return false;
		// },
		editProfile(fieldValue, id) {
			let formattedValue = fieldValue;
			if (dateKeys.includes(id)) {
				formattedValue = this.$moment(fieldValue).format(YMD_FORMAT);
			}
			const trans = {
				title: this.translate('edit_profile'),
				passwordTitle: this.translate('password'),
				notesTitle: this.translate('notes'),
			};

			const attributes = {
				emptyPasswordMessage: this.translate('password_required'),
			};

			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.passwordTitle, trans.notesTitle, attributes).then((response) => {
				const { value } = response;
				const payload = {
					password: value.password,
					notes: value.notes,
				};
				payload[id] = formattedValue;
				this.profileInfo.updatePersonalInfo(this.$route.params.customerId, payload).then(() => {
					this.alert.toast('success', this.translate('user_updated'));
					this.profile.getProfile(this.$route.params.customerId);
					this.personalExpanded = true;
				}).catch(() => {
					let errorName = 'default_error_message';
					if (this.hasPersonalInfoError('password')) {
						errorName = 'incorrect_password';
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		editAccountInfo(fieldValue, id) {
			const trans = {
				title: this.translate('edit_account'),
				passwordTitle: this.translate('password'),
				notesTitle: this.translate('notes'),
			};
			const attributes = {
				emptyPasswordMessage: this.translate('password_required'),
			};
			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.passwordTitle, trans.notesTitle, attributes).then((response) => {
				const { value } = response;
				const payload = {
					password: value.password,
					notes: value.notes,
				};
				payload[id] = fieldValue;
				if (dateKeys.includes(id) && fieldValue !== null) {
					payload[id] = this.$moment(fieldValue).format(YMD_FORMAT);
				}
				this.accountInformation.updateAccountInfo(this.$route.params.customerId, payload).then(() => {
					this.alert.toast('success', this.translate('user_updated'));
					this.accountInfo.getInformation(this.$route.params.customerId);
					this.accountExpanded = true;
				}).catch(() => {
					let errorName = 'default_error_message';
					if (this.hasAccountInfoError('password')) {
						errorName = 'incorrect_password';
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		// editSystemCredentials(fieldValue, id) {
		// 	const trans = {
		// 		title: this.translate('edit_system_credentials'),
		// 		passwordTitle: this.translate('password'),
		// 		notesTitle: this.translate('notes'),
		// 	};

		// 	const attributes = {
		// 		emptyPasswordMessage: this.translate('password_required'),
		// 	};

		// 	this.alert.confirmationWithPasswordAndNotes(trans.title, trans.passwordTitle, trans.notesTitle, attributes).then((response) => {
		// 		const { value } = response;
		// 		const payload = {
		// 			password: value.password,
		// 			notes: value.notes,
		// 		};

		// 		payload[id] = fieldValue;
		// 		if (passwordComponent.includes(id)) {
		// 			payload[id] = fieldValue.password;
		// 			payload[`${id}_confirmation`] = fieldValue.passwordConfirmation;
		// 		}

		// 		this.systemCredentials.updateSystemCredentials(this.$route.params.customerId, payload).then(() => {
		// 			this.alert.toast('success', this.translate('user_updated'));
		// 			this.systemCredentials.getSystemCredentials(this.$route.params.customerId);
		// 			this.credentialsExpanded = true;
		// 		}).catch(() => {
		// 			let errorName = 'default_error_message';
		// 			if (this.hasSystemCredentialsErrors('password')) {
		// 				errorName = 'incorrect_password';
		// 			}
		// 			this.alert.toast('error', this.translate(errorName));
		// 		});
		// 	}).catch(() => {});
		// },
		checkFieldInformation(item, data, section) {
			const fieldInformation = {};
			fieldInformation.value = data[item];
			fieldInformation.showable = data[item];
			fieldInformation.editable = this.editableFields.includes(item) && this.$can(section, 'update');
			if (item === 'status') {
				// Permission to change status is the same as permission to suspend users.
				fieldInformation.editable = this.$can(section, 'suspend') && !noEditableStatuses.includes(data[item]);
			}
			if (item === 'email') {
				fieldInformation.showable = `${data[item]} (${this.translate('marketing_email_status', { provider: marketingProvider })}: ${ this.translate(this.profileData.email_status).toLowerCase() })`;
			}
			if (item === 'password') {
				fieldInformation.value = '';
			}
			if (this.dateKeys.includes(item)) {
				let dateValue = this.translate('null_date_label');
				fieldInformation.editable = false;
				fieldInformation.editable = this.editableFields.includes(item) && this.$can(section, 'update');
				if (fieldInformation.value !== null) {
					dateValue = this.$moment(data[item].date).format(this.dateFormat);
					fieldInformation.value = this.$moment(data[item].date).toDate();
				}
				fieldInformation.showable = dateValue;
			} else if (this.translatedKeys.includes(item)) {
				fieldInformation.showable = '';
				if (data[item]) {
					fieldInformation.showable = this.translate(data[item].toLowerCase());
				}
			} else if (this.booleanKeys.includes(item)) {
				fieldInformation.showable = !!data[item];
				fieldInformation.value = !!data[item];
			}
			return fieldInformation;
		},
		clearPersonalError(field) {
			if (field) {
				delete this.personalInfoErrors[field];
				this.personalInfoErrors = { ...this.personalInfoErrors };
			}
		},
		clearAccountError(field) {
			if (field) {
				delete this.accountErrors[field];
				this.accountErrors = { ...this.accountErrors };
			}
		},
		// clearSystemCredentialsError(field) {
		// 	if (field) {
		// 		delete this.systemCredentialsErrors[field];
		// 		this.systemCredentialsErrors = { ...this.accountErrors };
		// 	}
		// },
		showField(item, data) {
			return (data[item] !== null || this.editableFields.includes(item)) && !INVISIBLE_FIELDS.includes(item);
		},
		getOptions(item) {
			if (item === 'status') return this.editableStatuses.includes(this.profileData.status) ? this.editableStatuses : [...[this.profileData.status], ...this.editableStatuses];
			return [];
		},
		collapseAll() {
			this.allExpanded = !this.allExpanded;
			if (this.allExpanded) {
				this.personalExpanded = true;
				this.accountExpanded = true;
				this.addressesExpanded = true;
				this.personalFilesExpanded = true;
				// this.credentialsExpanded = true;
			} else if (!this.allExpanded) {
				this.personalExpanded = false;
				this.accountExpanded = false;
				this.addressesExpanded = false;
				this.personalFilesExpanded = false;
				// this.credentialsExpanded = false;
			}
		},
		filterShowableFields(data) {
			const result = {};
			[...Object.entries(data)].forEach(([field, value]) => {
				if (
					typeof value !== 'undefined'
					&& (this.editableFields.includes(field) || value !== null)
					&& !INVISIBLE_FIELDS.includes(field)
				) {
					result[field] = value;
				}
			});
			return result;
		},
		duplicatedFields(field) {
			try {
				return this.profileData.duplicated_info[field];
			} catch (error) {
				return [];
			}
		},
	},
};
</script>

<style scoped>
.when-opened, .when-closed {
	float: right;
}

.collapsed > h5 > .when-opened,
:not(.collapsed) > h5 > .when-closed {
  display: none;
}
</style>
