<template>
	<div>
		<b-row>
			<b-col>
				<b-button
					v-if="$can('file', 'upload')"
					class="float-right mr-2"
					variant="primary"
					@click="$router.push({ name: addView, params: {'userId': userId} });">
					{{ translate('upload_file') }}
				</b-button>
			</b-col>
		</b-row>
		<hr>
		<b-row>
			<b-col>
				<div class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0 text-center">
									#
								</th>
								<th
									class="border-top-0 text-center">
									{{ translate('name') }}
								</th>
								<th
									class="border-top-0 text-center">
									{{ translate('date_time') }}
								</th>
								<th class="border-top-0 text-center">
									{{ translate('options') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!userFileLoading && userFileData">
							<tr
								v-for="(item, index) in userFileData"
								:key="index">
								<td class="text-center">
									{{ index + 1 }}
								</td>
								<td class="text-center">
									{{ item.attributes.name }}
								</td>
								<td class="text-center">
									{{ $moment(item.attributes.created_at.date).format(datetimeFormat) }}
								</td>
								<td class="text-center">
									<a
										v-b-tooltip.hover
										:title="translate('open_in_new_tab')"
										:href="item.attributes.url"
										target="_blank">
										<i class="fas fa-external-link-alt align-icon align-self-center pointer" />
									</a>
									<a
										v-b-tooltip.hover
										class="ml-3"
										:title="translate('delete')"
										@click="deleteFile(item.id)">
										<i class="fas fa-trash-alt text-danger align-icon align-self-center pointer" />
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="userFileLoading"
			:has-data="userFileHasData" />
	</div>
</template>
<script>
import { YMDHMS_FORMAT } from '@/settings/Dates';
import UserFile from '@/util/UserFile';
import {
	Tooltip, Grids, UserFiles,
} from '@/translations';

export default {
	name: 'FilesTable',
	messages: [Tooltip, Grids, UserFiles],
	props: {
		userId: {
			type: String,
			default: '',
		},
		addView: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			userFile: new UserFile(),
			deleteUserFile: new UserFile(),
			datetimeFormat: YMDHMS_FORMAT,
			alert: new this.$Alert(),
		};
	},
	computed: {
		userFileLoading() {
			try {
				return this.userFile.data.loading;
			} catch (error) {
				return false;
			}
		},
		userFileData() {
			try {
				const { data } = this.userFile.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		userFileHasData() {
			const response = this.userFileData ? Object.keys(this.userFileData).length : false;
			return !!response;
		},
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.userFile.getFiles(this.userId);
		},
		deleteFile(fileId) {
			const trans = {
				title: this.translate('delete_file'),
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};
			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				const payload = {
					password: password.value,
				};
				this.deleteUserFile.deleteFile(fileId, payload).then(() => {
					try {
						this.alert.toast('success', this.translate('file_deleted'));
					} finally {
						this.getData();
					}
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.deleteUserFile.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
						delete this.deleteUserFile.data.errors.errors.password;
					}
					this.alert.toast('error', this.translate(errorName));
					this.data = payload;
				});
			}).catch(() => {});
		},
	},
};
</script>
