import { GET_CUSTOMERS_PROFILE, GET_CUSTOMERS_ACCOUNT_INFORMATION, UPDATE_CUSTOMER_ACCOUNT_INFO } from '@/config/endpoint';
import Req from './AxiosRequest';

class CustomersInformation {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getProfile(userId) {
		const { method, endpoint } = GET_CUSTOMERS_PROFILE;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getInformation(userId) {
		const { method, endpoint } = GET_CUSTOMERS_ACCOUNT_INFORMATION;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateAccountInfo(userId, payload) {
		const { method, endpoint } = UPDATE_CUSTOMER_ACCOUNT_INFO;
		return this.data[method](endpoint(userId), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default CustomersInformation;
